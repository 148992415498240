import axios from "axios";
import React, { useEffect, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Appbar from "../../common/Appbar";

const Home = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASEURL}user/product/viewAll`)
        .then((res) => {
          setProducts(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <div>
      <Appbar />
    
      <section>
        <div className="container mt-5 pt-5">
          <div className="row justify-content-center">
            <div className="col-md-12 col-xl-10">
              <h2 className="mb-2">All Products</h2>
              <div className="row mt-4">
                {loading ? (
                  <ThreeDots
                    height="40"
                    width="80"
                    radius="9"
                    color="#3D83F5"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : products && products.length > 0 ? (
                  products.map((item, index) => (
                    <Link
                      to={`/product?id=${item._id}`}
                      className=" col-md-6 col-lg-4"
                      key={index}
                    >
                      <div
                        className="card shadow-0 border rounded-3 mb-3"
                        style={{
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className=" my-4">
                              <div className="bg-image hover-zoom ripple rounded ripple-surface">
                                <img
                                  src={
                                    process.env.REACT_APP_BUCKET_BASE_URL +
                                    item?.image
                                  }
                                  className="w-100"
                                  height="100px"
                                  style={{ objectFit: "contain" }}
                                  alt=""
                                />
                                <a href="#!">
                                  <div className="hover-overlay">
                                    <div
                                      className="mask"
                                      style={{
                                        backgroundColor:
                                          "rgba(253, 253, 253, 0.15)",
                                      }}
                                    ></div>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="">
                              <h5 className="mt-2">{item.name}</h5>
                              <p
                                className="mt-2 mb-4 mb-md-0 truncate-desc "
                                style={{ minHeight: "50px" }}
                              >
                                {item.description}
                              </p>
                            </div>
                            <div className=" border-sm-start-none d-flex flex-column  flex-row align-items-center mb-1 justify-content-center">
                              {/* <h4 className="mb-1 me-1">${item.price}</h4> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))
                ) : (
                  <h4 className="text-danger text-center">No Products</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
