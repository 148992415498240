import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Appbar from "../../common/Appbar";
import VideoElement from "./VideoElement";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import AffiliateDropDown from "../../common/AffiliateDropDown";

const Video = () => {
  const location = useLocation();
  const componentRefs = useRef([]);
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("id");
  const videoId = queryParams.get("videoId");
  const [muted, setMuted] = useState(true);
  const [initialSlide, setInitialSlide] = useState();
  const [product, setProduct] = useState();
  const [affiliateProduct, setAffiliateProduct] = useState();
  const [showAffiliate, setShowAffiliate] = useState(false);
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(true);
  const popupRef = useRef();

  const isLoggedIn = localStorage.getItem("token");
  let user;
  if (isLoggedIn) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_BASEURL}${
            user?.role === "admin"
              ? `admin/product/view/${productId}`
              : `user/product/${
                  isLoggedIn ? "viewAuthenticated" : "view"
                }/${productId}`
          }`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setProduct(res.data.data);
          setResponse(res.data.data);
          setAffiliateProduct(res.data.data.affiliateProducts);
          componentRefs.current = Array(res.data.data.videos.length)
            .fill(null)
            .map(
              (_, index) => componentRefs.current[index] || React.createRef()
            );
          const index = res.data.data.videos.findIndex(function (obj) {
            return obj._id === videoId;
          });
          setInitialSlide(index);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
        });
    };
    fetchData();
  }, [productId]);

  return (
    <div>
      {" "}
      {loading ? (
        <ThreeDots
          height="40"
          width="80"
          radius="9"
          color="#3D83F5"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            marginTop: "150px",
          }}
          wrapperClassName="spinner"
          visible={true}
        />
      ) : (
        <>
          <div className="shop_modal" style={{ marginTop: "0px" }}>
            <div className="shop_header">
              <div className="container ">
                <div className="head_wrap d-flex justify-content-between align-items-center">
                  <h1
                    className="f-20 mb-0"
                    style={{
                      maxWidth: window.innerWidth - 120,
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {product?.name}
                  </h1>
                  <div className="shop_btn">
                    {affiliateProduct &&
                      affiliateProduct.length > 0 &&
                      !showAffiliate && (
                        <button
                          className="themeBtn-blue"
                          onClick={() => {
                            setShowAffiliate(true);
                          }}
                        >
                          Shop
                        </button>
                      )}
                    {showAffiliate && (
                      <button
                        className="cross_btn"
                        onClick={() => {
                          setShowAffiliate(false);
                        }}
                      >
                        <img
                          src="assets/images/cros.png"
                          alt="cros"
                          className="img-fluid"
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {showAffiliate && (
              <div
                className="shop_body"
                id="shop_body"
                style={{ top: "45px", zIndex: 10 }}
              >
                <div
                  className="container "
                  ref={popupRef}
                  style={{
                    maxHeight: "50vh",
                    overflow: "auto",
                    padding: "10px",
                  }}
                >
                  {affiliateProduct &&
                    affiliateProduct.length > 0 &&
                    affiliateProduct.map((item, index) => (
                      <AffiliateDropDown
                        key={index}
                        item={item}
                        setShowAffiliate={setShowAffiliate}
                        popupRef={popupRef}
                      />
                    ))}
                </div>
              </div>
            )}
          </div>

          <div
            className="bg-black"
            style={{ height: "calc(100vh)", overflow: "hidden" }}
          >
            <Swiper
              slidesPerView={1}
              initialSlide={initialSlide}
              direction="vertical"
              mousewheel
              pagination
              height={window.innerHeight}
            >
              {product &&
                product.videos.length > 0 &&
                product.videos.map((video, index) => (
                  <SwiperSlide>
                    <VideoElement
                      product={product}
                      setProduct={setProduct}
                      index={index}
                      video={video}
                      ref={componentRefs.current[index]}
                      muted={muted}
                      setMuted={setMuted}
                    />
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </>
      )}
    </div>
  );
};

export default Video;
