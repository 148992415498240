import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Appbar from "../../common/Appbar";
import VideoElement from "./VideoElement";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

const FavouritesVideos = () => {
  const location = useLocation();
  const componentRefs = useRef([]);
  const queryParams = new URLSearchParams(location.search);
  const videoId = queryParams.get("videoId");
  const [muted, setMuted] = useState(true);
  const [initialSlide, setInitialSlide] = useState();
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);

  const isLoggedIn = localStorage.getItem("token");
  let user;
  if (isLoggedIn) {
    user = JSON.parse(localStorage.getItem("user"));
  }

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASEURL}user/likeVideo/allLikes`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setVideos(res.data.data);
          componentRefs.current = Array(res.data.data.length)
            .fill(null)
            .map(
              (_, index) => componentRefs.current[index] || React.createRef()
            );
          const index = res.data.data.findIndex(function (obj) {
            return obj.product.videos._id === videoId;
          });
          setInitialSlide(index);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  return (
    <div>
      {" "}
      {loading ? (
        <ThreeDots
          height="40"
          width="80"
          radius="9"
          color="#3D83F5"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            marginTop: "150px",
          }}
          wrapperClassName="spinner"
          visible={true}
        />
      ) : (
        <>
          <Swiper
            slidesPerView={1}
            initialSlide={initialSlide}
            direction="vertical"
            mousewheel
            pagination
            height={window.innerHeight}
          >
            {videos &&
              videos.length > 0 &&
              videos.map((video, index) => (
                <SwiperSlide key={video.product.videos._id}>
                  <VideoElement
                    product={video}
                    products={videos}
                    setProduct={setVideos}
                    index={index}
                    videoId={videoId}
                    video={video.product.videos}
                    ref={componentRefs.current[index]}
                    muted={muted}
                    setMuted={setMuted}
                  />
                </SwiperSlide>
              ))}
          </Swiper>
        </>
      )}
    </div>
  );
};

export default FavouritesVideos;
