import { useSortable } from "@dnd-kit/sortable";
import React, { useState, useEffect } from "react";
import { CSS } from "@dnd-kit/utilities";
import { v4 as uuidv4 } from "uuid";

function VideoShowSection({
  setVideoInitialValues,
  item,
  isEdit,
  setIsEditVideo,
  setDeleteVideo,
  index,
  id,
  allvideos,
  setValues,
  values,
  dragVideosfunc
}) {
  const {
    attributes,
    setNodeRef,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: id,
    data: {
      type: "item",
    },
  });

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mobileMediaQuery = window.matchMedia("(max-width: 767px)"); // Adjust the breakpoint as needed

    const handleMobileChange = (event) => {
      setIsMobile(event.matches);
    };

    mobileMediaQuery.addEventListener("change", handleMobileChange);
    setIsMobile(mobileMediaQuery.matches);

    return () => {
      mobileMediaQuery.removeEventListener("change", handleMobileChange);
    };
  }, []);

  const moveStage = (fromIndex, toIndex) => {
    const updatedVideos = [...values.video];
    const [movedVideos] = updatedVideos.splice(fromIndex, 1);
    updatedVideos.splice(toIndex, 0, movedVideos);
    setValues({ ...values, video: updatedVideos });
    dragVideosfunc(updatedVideos);
  };

  return (
    <div
      className={`col-md-6 col-lg-4 px-0 ${isDragging && "opacity-50"}`}
      {...attributes}
      ref={setNodeRef}
      style={{
        transition,
        transform: CSS.Translate.toString(transform),
      }}
    >
      {item && (
        <div
          className="mx-2 card shadow-0 border rounded-3 mb-3"
          style={{
            boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          }}
        >
          <div className="d-flex justify-content-end mt-2">
            <i
              className="mx-1 crud-button crud-primary crud-touch crud-edit text-primary"
              onClick={() => {
                setVideoInitialValues({
                  id: item.id,
                  title: item.title,
                  location: item.location,
                  productionDate: item.productionDate,
                  video: isEdit ? [item.video] : [item.video[0]],
                  thumbnail: isEdit
                    ? item.thumbnail && item.thumbnail.length > 0
                      ? [item.thumbnail]
                      : []
                    : [item.thumbnail[0]],
                });

                setIsEditVideo(item?._id ? item?._id : index);
              }}
            ></i>
            <i
              class="mx-1 crud-button crud-primary crud-touch crud-delete text-danger"
              onClick={() => {
                setDeleteVideo(item?._id ? item?._id : index);
              }}
            ></i>
          </div>
          <div className="card-body pt-0">
            <div className="row">
              <div className="mb-4 mb-lg-0">
                <div className="bg-image hover-zoom ripple rounded ripple-surface">
                  <video
                    key={`video-${index}`}
                    src={
                      isEdit
                        ? process.env.REACT_APP_BUCKET_BASE_URL + item.video
                        : URL.createObjectURL(item.video[0])
                    }
                    controls
                    height="150px"
                    width="100%"
                    style={{
                      objectFit: "contain",
                    }}
                  >
                    Your browser does not support the video tag.
                  </video>
                  <a href="#!">
                    <div className="hover-overlay">
                      <div
                        className="mask"
                        style={{
                          backgroundColor: "rgba(253, 253, 253, 0.15)",
                        }}
                      ></div>
                    </div>
                  </a>
                </div>
              </div>
              <div
                className={`border-sm-start-none ${
                  isMobile ? "" : "video-section"
                }`}
                {...(!isMobile ? listeners : {})}
              >
                {isMobile && (
                  <div className="arrows-icons">
                    {index >= 1 && (
                      <div
                        className="drag-up crud-button"
                        onClick={() =>
                          moveStage(index, index - 1)
                        }
                      >
                        <img
                          src="assets/images/arrow-alt-circle-up-solid.svg"
                          alt="arrow-up"
                          width={18}
                        />
                      </div>
                    )}
                    {index < allvideos.length - 1 && (
                      <div
                        className="drag-up crud-button"
                        onClick={() => moveStage(index, index + 1)}
                      >
                        <img
                          src="assets/images/arrow-alt-circle-down-solid.svg"
                          alt="arrow-up"
                          width={18}
                        />
                      </div>
                    )}
                  </div>
                )}
                <div className="mb-1">
                  <p className="mt-2 mb-1 me-1 truncate-desc-one w-100">
                    Title: {item.title}
                  </p>
                  <p className="mb-1 me-1 truncate-desc-one w-100">
                    Location: {item.location}
                  </p>
                  <p className="mb-1 me-1 truncate-desc-one w-100">
                    Date: {item.productionDate}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default VideoShowSection;
