import { ErrorMessage, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import AdminSidebar from "../../../common/AdminSidebar";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import OverlayLoader from "../../../common/OverlayLoader";
import CreateEditModal from "./CreateEditModal";
import DeleteConfirmationModal from "../../../common/DeleteConfirmationModal";
import Biodiversity from "./Biodiversity";
import Video from "./Video";
import Decarbonization from "./Decarbonization";
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  closestCorners,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import VideoShowSection from "./videoShowSection";
import { v4 as uuidv4 } from "uuid";

const CreateProduct = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isEdit = queryParams.get("edit");
  const id = queryParams.get("id");
  const [loading, setLoadng] = useState(false);
  const [getEditLoading, setGetLoading] = useState(isEdit ? true : false);
  const [error, setError] = useState("");

  const [affiliateProducts, setAffiliateProducts] = useState([]);
  const [addNewAffiliate, setAddNewAffiliate] = useState(false);
  const [isEditAffiliate, setIsEditAffiliate] = useState("");
  const [deleteAffiliate, setDeleteAffiliate] = useState("");

  const [biodiversity, setBiodiversity] = useState([]);
  const [addNewBio, setAddNewBio] = useState(false);
  const [isEditBio, setIsEditBio] = useState("");
  const [deleteBio, setDeleteBio] = useState("");

  const [decarbon, setDecarbon] = useState([]);
  const [addNewDecarbon, setAddNewDecarbon] = useState(false);
  const [isEditDecarbon, setIsEditDecarbon] = useState("");
  const [deleteDecarbon, setDeleteDecarbon] = useState("");

  const [addNewVideo, setAddNewVideo] = useState(false);
  const [dragVideos, setDragVideos] = useState(null);
  const [isEditVideo, setIsEditVideo] = useState("");
  const [deleteVideo, setDeleteVideo] = useState("");
  const [activeId, setActiveId] = useState(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
    price: Yup.number()
      .required("Price is required")
      .positive("Price must be greater than 0"),
    image: isEdit ? Yup.array() : Yup.array().min(1, "One image is required"),
    video: Yup.array(),
  });

  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    price: "",
    image: [],
    video: [],
  });

  const [videoinitialValues, setVideoInitialValues] = useState({
    id: `item-${uuidv4()}`,
    title: "",
    location: "",
    productionDate: "",
    video: [],
    thumbnail: [],
  });

  const [affiliateinitialValues, setAffiliateInitialValues] = useState({
    title: "",
    link: "",
    image: [],
  });

  const [bioinitialValues, setBioInitialValues] = useState({
    title: "",
    desc: "",
    image: [],
  });

  const [decarboninitialValues, setDecarbonInitialValues] = useState({
    title: "",
    desc: "",
    image: [],
  });

  const handleImageUpload = (event, values, setValues) => {
    const files = event.target.files;
    const selectedFiles = Array.from(files);

    const newSelectedImages = [];

    selectedFiles.forEach((file) => {
      if (file.type.startsWith("image/")) {
        newSelectedImages.push(file);
      }
    });
    setValues({
      ...values,
      image: [...newSelectedImages],
      imageUrl: [],
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleSubmit = async (values, resetForm) => {
    // if (
    //   (isEdit,
    //   Array.isArray(values.imageUrl),
    //   values.imageUrl?.length === 0,
    //   values.image.length === 0)
    // ) {
    //   setError("One image is required");
    // } else {
    setError("");
    setLoadng(true);

    const formDataToSend = new FormData();
    const data = JSON.parse(JSON.stringify(values));
    for (const key in values) {
      if (key === "image" || key === "video") {
        for (const entry in values[key]) {
          if (key === "video") {
            for (const entry1 in values[key][entry]) {
              if (
                entry1 === "order" ||
                entry1 === "id" ||
                entry1 === "title" ||
                entry1 === "location" ||
                entry1 === "productionDate"
              ) {
              } else {
                formDataToSend.append([entry1], values[key][entry][entry1][0]);
                delete data[key][entry][entry1];
              }
            }
          } else {
            formDataToSend.append([key], values[key][entry]);
          }
        }
      } else formDataToSend.append(key, values[key]);
    }
    const stringifyVideos = JSON.stringify(data.video);
    if (!isEdit) {
      formDataToSend.append("videos", stringifyVideos);
    } else {
      formDataToSend.delete("video");
    }

    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}${
          isEdit ? "admin/product/updateProduct/" + id : "admin/product/add"
        }`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadng(false);
        toast.success(res.data.message);
        if (isEdit) {
          // navigate("/all-products");
        } else {
          navigate(`/edit-product?edit=true&id=${res.data.data._id}`);
        }
      })
      .catch((error) => {
        setLoadng(false);
        toast.error(error.response.data.message);
      });
    // }
  };

  const handleDeleteVideo = async (videoId, deleteFromValues) => {
    setLoadng(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASEURL}admin/product/deleteProductVideo/${id}/${videoId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadng(false);
        deleteFromValues();
        toast.success(res.data.message);
      })
      .catch((error) => {
        setLoadng(false);
        toast.error(error.response.data.message);
      });
  };
  const handleDeleteAffiliate = async (productid, deleteFromValues) => {
    setLoadng(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASEURL}admin/product/deleteAffiliateProduct/${id}/${productid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadng(false);
        deleteFromValues();
        toast.success(res.data.message);
      })
      .catch((error) => {
        setLoadng(false);
        toast.error(error.response.data.message);
      });
  };

  const handleDeleteBiodiversity = async (bioId, deleteFromValues) => {
    setLoadng(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASEURL}admin/product/deleteBiodiversity/${id}/${bioId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadng(false);
        deleteFromValues();
        toast.success(res.data.message);
      })
      .catch((error) => {
        setLoadng(false);
        toast.error(error.response.data.message);
      });
  };

  const handleDeleteDecarbon = async (decarbonId, deleteFromValues) => {
    setLoadng(true);
    await axios
      .get(
        `${process.env.REACT_APP_BASEURL}admin/product/deleteDecarbonisation/${id}/${decarbonId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadng(false);
        deleteFromValues();
        toast.success(res.data.message);
      })
      .catch((error) => {
        setLoadng(false);
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASEURL}admin/product/view/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          const product = res.data.data;
          setDragVideos(product.videos);
          setInitialValues({
            name: product.name,
            description: product.description,
            price: product.price,
            image: [],
            imageUrl: [product.image],
            video: product.videos,
          });
          setAffiliateProducts(product.affiliateProducts);
          setBiodiversity(product.biodiversities);
          setDecarbon(product.decarbonisations);
          setGetLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setGetLoading(false);
        });
    };
    if (id && isEdit) {
      fetchData();
    }
  }, []);

  const dragVideosfunc = async (newVideos) => {
    const body = {
      videos: newVideos.map((item, index) => {
        return { _id: item._id, order: index + 1 };
      }),
    };

    if (JSON.stringify(newVideos) !== JSON.stringify(dragVideos)) {
      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}${
            "admin/product/handleDragVideo/" + id
          }`,
          body,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          // toast.success(res.data.message);
          setDragVideos(newVideos);
          setActiveId(null);
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  return (
    <div className="d-flex" style={{ height: "100vh", overflow: "hidden" }}>
      <AdminSidebar setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
      <section
        className="content"
        style={{
          width: "calc(100% -  220px)",
          overflow: "auto",
          maxHeight: "100vh",
          minHeight: "100vh",
        }}
      >
        {loading && <OverlayLoader />}
        <div className="container py-5">
          <div className="row justify-content-center mb-3">
            <div className="col-md-12 col-xl-10">
              <h2 className="mb-5">{isEdit ? "Update" : "Create"} Product</h2>

              {getEditLoading ? (
                <ThreeDots
                  height="40"
                  width="80"
                  radius="9"
                  color="#3D83F5"
                  ariaLabel="three-dots-loading"
                  wrapperStyle={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  wrapperClassName="spinner"
                  visible={true}
                />
              ) : (
                <Formik
                  initialValues={{ ...initialValues }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { resetForm }) =>
                    handleSubmit(values, resetForm)
                  }
                >
                  {({ values, setValues, handleChange, handleBlur }) => (
                    <>
                      {(deleteAffiliate !== "" ||
                        deleteBio !== "" ||
                        deleteVideo !== "" ||
                        deleteDecarbon !== "") && (
                        <DeleteConfirmationModal
                          setDeleteModal={
                            deleteAffiliate !== ""
                              ? setDeleteAffiliate
                              : deleteBio !== ""
                              ? setDeleteBio
                              : deleteDecarbon !== ""
                              ? setDeleteDecarbon
                              : setDeleteVideo
                          }
                          disabled={false}
                          handleSubmit={() => {
                            if (deleteAffiliate !== "") {
                              handleDeleteAffiliate(deleteAffiliate, () => {
                                setAffiliateProducts(
                                  affiliateProducts.filter(
                                    (item, index) =>
                                      item._id !== deleteAffiliate
                                  )
                                );
                                setDeleteAffiliate("");
                              });
                            }
                            if (deleteBio !== "") {
                              handleDeleteBiodiversity(deleteBio, () => {
                                setBiodiversity(
                                  biodiversity.filter(
                                    (item, index) => item._id !== deleteBio
                                  )
                                );
                                setDeleteBio("");
                              });
                            }
                            if (deleteDecarbon !== "") {
                              handleDeleteDecarbon(deleteDecarbon, () => {
                                setDecarbon(
                                  decarbon.filter(
                                    (item, index) => item._id !== deleteDecarbon
                                  )
                                );
                                setDecarbon("");
                              });
                            }
                            if (deleteVideo !== "") {
                              if (isEdit) {
                                handleDeleteVideo(deleteVideo, () => {
                                  setValues({
                                    ...values,
                                    video: values.video.filter(
                                      (item, index) => item._id !== deleteVideo
                                    ),
                                  });
                                  setDeleteVideo("");
                                });
                              } else {
                                setValues({
                                  ...values,
                                  video: values.video.filter(
                                    (item, index) => index !== deleteVideo
                                  ),
                                });
                                setDeleteVideo("");
                              }
                            }
                          }}
                        />
                      )}

                      {(addNewVideo || isEditVideo !== "") && (
                        <Video
                          isEditForm={isEdit}
                          isEdit={isEditVideo}
                          setIsEdit={setIsEditVideo}
                          addNew={addNewVideo}
                          setAddNew={setAddNewVideo}
                          productValues={values}
                          setValues={setValues}
                          initialValues={videoinitialValues}
                          setInitialValues={setVideoInitialValues}
                          setLoadng={setLoadng}
                          loading={loading}
                          id={id}
                        />
                      )}
                      {(addNewBio || isEditBio !== "") && (
                        <Biodiversity
                          isEdit={isEdit}
                          isEditBio={isEditBio}
                          setIsEditBio={setIsEditBio}
                          addNew={addNewBio}
                          setAddNew={setAddNewBio}
                          productValues={values}
                          setValues={setValues}
                          initialValues={bioinitialValues}
                          setInitialValues={setBioInitialValues}
                          biodiversity={biodiversity}
                          setBiodiversity={setBiodiversity}
                          setLoadng={setLoadng}
                          loading={loading}
                          id={id}
                        />
                      )}
                      {(addNewDecarbon || isEditDecarbon !== "") && (
                        <Decarbonization
                          isEdit={isEdit}
                          isEditDecarbon={isEditDecarbon}
                          setIsEditDecarbon={setIsEditDecarbon}
                          addNew={addNewDecarbon}
                          setAddNew={setAddNewDecarbon}
                          productValues={values}
                          setValues={setValues}
                          initialValues={decarboninitialValues}
                          setInitialValues={setDecarbonInitialValues}
                          decarbon={decarbon}
                          setDecarbon={setDecarbon}
                          setLoadng={setLoadng}
                          loading={loading}
                          id={id}
                        />
                      )}
                      {(addNewAffiliate || isEditAffiliate !== "") && (
                        <CreateEditModal
                          isEdit={isEdit}
                          isEditAffiliate={isEditAffiliate}
                          setIsEditAffiliate={setIsEditAffiliate}
                          addNew={addNewAffiliate}
                          setAddNew={setAddNewAffiliate}
                          initialValues={affiliateinitialValues}
                          setInitialValues={setAffiliateInitialValues}
                          affiliateProducts={affiliateProducts}
                          setAffiliateProducts={setAffiliateProducts}
                          setLoadng={setLoadng}
                          loading={loading}
                          id={id}
                        />
                      )}
                      <Form>
                        <div className="row mb-4">
                          <div className="col-md-12 col-lg-6">
                            <div className="form-outline">
                              <label className="form-label" htmlFor="Name">
                                Name
                              </label>
                              <input
                                type="text"
                                id="Name"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="name"
                                value={values.name}
                              />
                              <ErrorMessage
                                name="name"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-6">
                            <div className="form-outline">
                              <label className="form-label" htmlFor="price">
                                Price
                              </label>
                              <input
                                type="number"
                                id="price"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="price"
                                value={values.price}
                              />
                              <ErrorMessage
                                name="price"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="form-outline mb-4">
                          <label className="form-label" htmlFor="Description">
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            id="Description"
                            rows="4"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="description"
                            value={values.description}
                          ></textarea>
                          <ErrorMessage
                            name="description"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                        <div className="mb-4">
                          <div className="col-md-6">
                            <div className="col-md-12">
                              <div className="form-outline">
                                <label
                                  className="form-label w-100"
                                  htmlFor="customFile"
                                >
                                  Image
                                  <div>
                                    <span className="btn btn-primary btn-block mt-1 w-100">
                                      Upload Image
                                    </span>
                                  </div>
                                </label>
                                <input
                                  type="file"
                                  className="form-control"
                                  id="customFile"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  multiple={false}
                                  onChange={(e) => {
                                    handleImageUpload(e, values, setValues);
                                  }}
                                />
                                {error && (
                                  <div className="error text-danger">
                                    {error}
                                  </div>
                                )}
                                <ErrorMessage
                                  name="image"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                              <div className="row">
                                {values.image &&
                                  values.image.length > 0 &&
                                  values.image.map((image, index) => (
                                    <div
                                      className="position-relative d-flex justify-content-center mt-2 col-5 mx-1"
                                      id="hover-btn"
                                      style={{
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                      }}
                                    >
                                      <div
                                        className="position-absolute w-100 h-100"
                                        id="icon"
                                      >
                                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                          <i
                                            className="fa fa-3x fa-times cursor-pointer icon-white "
                                            onClick={() => {
                                              setValues({
                                                ...values,
                                                image: values.image.filter(
                                                  (item) => item !== image
                                                ),
                                              });
                                            }}
                                          ></i>
                                        </div>
                                      </div>
                                      <img
                                        key={`image-${index}`}
                                        src={URL.createObjectURL(image)}
                                        alt={""}
                                        height="150px"
                                        style={{ objectFit: "contain" }}
                                      />
                                    </div>
                                  ))}
                                {isEdit &&
                                  values.imageUrl &&
                                  values.imageUrl.length > 0 &&
                                  values.imageUrl.map((url, index) => (
                                    <div
                                      className="position-relative d-flex justify-content-center mt-2 col-5 mx-1"
                                      id="hover-btn"
                                      style={{
                                        boxShadow:
                                          "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                      }}
                                    >
                                      <div
                                        className="position-absolute w-100 h-100"
                                        id="icon"
                                      >
                                        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                          <i
                                            className="fa fa-3x fa-times cursor-pointer icon-white "
                                            onClick={() => {
                                              setValues({
                                                ...values,
                                                imageUrl:
                                                  values.imageUrl.filter(
                                                    (item) => item !== url
                                                  ),
                                              });
                                            }}
                                          ></i>
                                        </div>
                                      </div>
                                      <img
                                        key={`image-${index}`}
                                        src={
                                          process.env
                                            .REACT_APP_BUCKET_BASE_URL + url
                                        }
                                        alt={""}
                                        height="150px"
                                        style={{ objectFit: "contain" }}
                                      />
                                    </div>
                                  ))}
                              </div>
                            </div>
                          </div>
                          {isEdit && (
                            <div className="d-flex justify-content-center ">
                              <button
                                type="submit"
                                className="btn btn-primary btn-block mb-4 w-25"
                                disabled={loading}
                              >
                                {loading
                                  ? "Submitting..."
                                  : isEdit
                                  ? "Update"
                                  : "Submit"}
                              </button>
                            </div>
                          )}
                          <div className="mt-4">
                            <div className="mb-5 mt-5">
                              <div className="row">
                                <h3 className="col-6">Add Videos</h3>
                                <div className="col-6 d-flex justify-content-end">
                                  <button
                                    type="button"
                                    className="btn btn-primary btn-block mb-4 px-5"
                                    onClick={() => {
                                      setAddNewVideo(true);
                                    }}
                                  >
                                    Add
                                  </button>
                                </div>
                                <ErrorMessage
                                  name="video"
                                  component="div"
                                  className="error text-danger"
                                />

                                {values.video && values.video.length > 0 && (
                                  <DndContext
                                    sensors={sensors}
                                    collisionDetection={closestCorners}
                                    onDragStart={({ active }) => {
                                      setActiveId(active);
                                    }}
                                    onDragEnd={({ active, over }) => {
                                      if (over && active.id !== over?.id) {
                                        const activeIndex =
                                          values.video.findIndex(
                                            ({ id }) => id === active.id
                                          );
                                        const overIndex =
                                          values.video.findIndex(
                                            ({ id }) => id === over.id
                                          );

                                        let newItems = [...values.video];
                                        newItems = arrayMove(
                                          newItems,
                                          activeIndex,
                                          overIndex
                                        );

                                        setValues({
                                          ...values,
                                          video: newItems,
                                        });
                                        dragVideosfunc(newItems);
                                      }
                                      setActiveId(null);
                                    }}
                                    onDragCancel={() => {
                                      setActiveId(null);
                                    }}
                                  >
                                    <SortableContext items={values.video}>
                                      <div className="row overflow-auto">
                                        {values.video.length > 0 &&
                                          values.video.map((item, index) => (
                                            <VideoShowSection
                                              key={item.id}
                                              item={item}
                                              setVideoInitialValues={
                                                setVideoInitialValues
                                              }
                                              isEdit={isEdit}
                                              setIsEditVideo={setIsEditVideo}
                                              setDeleteVideo={setDeleteVideo}
                                              index={index}
                                              values={values}
                                              setValues={setValues}
                                              id={item.id}
                                              dragVideosfunc={dragVideosfunc}
                                              allvideos={values.video}
                                            />
                                          ))}
                                      </div>
                                    </SortableContext>
                                    <DragOverlay adjustScale={false}>
                                      {/* Drag Overlay For Container */}
                                      {activeId &&
                                        activeId
                                          .toString()
                                          .includes("item") && (
                                          <VideoShowSection id={activeId} />
                                        )}
                                    </DragOverlay>
                                  </DndContext>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {!isEdit && (
                          <div className="d-flex justify-content-center ">
                            <button
                              type="submit"
                              className="btn btn-primary btn-block mb-4 w-25"
                              disabled={loading}
                            >
                              {loading
                                ? "Submitting..."
                                : isEdit
                                ? "Update"
                                : "Submit"}
                            </button>
                          </div>
                        )}
                        <div className="mb-5 mt-5">
                          <div className="row">
                            <h3 className="col-6">Affiliate Products</h3>
                            <div className="col-6 d-flex justify-content-end">
                              {affiliateProducts.length < 3 && (
                                <button
                                  type="button"
                                  className="btn btn-primary btn-block mb-4 px-5"
                                  disabled={!isEdit}
                                  onClick={() => {
                                    setAddNewAffiliate(true);
                                  }}
                                >
                                  Add
                                </button>
                              )}
                            </div>
                            <ErrorMessage
                              name="affiliate"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                          <div className="row overflow-auto">
                            {affiliateProducts &&
                              affiliateProducts.length > 0 &&
                              affiliateProducts.map((item, index) => (
                                <div
                                  className="col-md-6 col-lg-4 px-0"
                                  key={index}
                                >
                                  <div
                                    className="mx-2 card shadow-0 border rounded-3 mb-3"
                                    style={{
                                      boxShadow:
                                        "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                    }}
                                  >
                                    <div className="d-flex justify-content-end mt-2">
                                      <i
                                        className="mx-1 crud-button crud-primary crud-touch crud-edit text-primary"
                                        onClick={() => {
                                          setAffiliateInitialValues({
                                            title: item.title,
                                            link: item.link,
                                            imageUrl: [item.image],
                                            image: [],
                                          });

                                          setIsEditAffiliate(item?._id);
                                        }}
                                      ></i>
                                      <i
                                        class="mx-1 crud-button crud-primary crud-touch crud-delete text-danger"
                                        onClick={() => {
                                          setDeleteAffiliate(item?._id);
                                        }}
                                      ></i>
                                    </div>
                                    <div className="card-body pt-0">
                                      <div className="row text-center">
                                        <div className="mb-4 mb-lg-0">
                                          <div className="bg-image hover-zoom ripple rounded ripple-surface">
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_BUCKET_BASE_URL +
                                                item?.image
                                              }
                                              className="w-100"
                                              height="100px"
                                              style={{ objectFit: "contain" }}
                                              alt=""
                                            />
                                            <a href="#!">
                                              <div className="hover-overlay">
                                                <div
                                                  className="mask"
                                                  style={{
                                                    backgroundColor:
                                                      "rgba(253, 253, 253, 0.15)",
                                                  }}
                                                ></div>
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                        <div className="border-sm-start-none">
                                          <div className="d-flex flex-row align-items-center mb-1">
                                            <h5 className="mb-1 me-1 truncate-desc-one text-center w-100">
                                              {item.title}
                                            </h5>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="mb-5 mt-5">
                          <div className="row">
                            <h3 className="col-6">Biodiversity</h3>
                            <div className="col-6 d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn btn-primary btn-block mb-4 px-5"
                                disabled={!isEdit}
                                onClick={() => {
                                  setAddNewBio(true);
                                }}
                              >
                                Add
                              </button>
                            </div>
                            <ErrorMessage
                              name="biodiversity"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                          <div className="row overflow-auto">
                            {biodiversity &&
                              biodiversity.length > 0 &&
                              biodiversity.map((item, index) => (
                                <div
                                  className="col-md-6 col-lg-4 px-0"
                                  key={index}
                                >
                                  <div
                                    className="mx-2 card shadow-0 border rounded-3 mb-3"
                                    style={{
                                      boxShadow:
                                        "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                    }}
                                  >
                                    <div className="d-flex justify-content-end mt-2">
                                      <i
                                        className="mx-1 crud-button crud-primary crud-touch crud-edit text-primary"
                                        onClick={() => {
                                          setBioInitialValues({
                                            title: item.title,
                                            desc: item.desc,
                                            imageUrl: [item.image],
                                            image: [],
                                          });

                                          setIsEditBio(item?._id);
                                        }}
                                      ></i>
                                      <i
                                        class="mx-1 crud-button crud-primary crud-touch crud-delete text-danger"
                                        onClick={() => {
                                          setDeleteBio(item?._id);
                                        }}
                                      ></i>
                                    </div>
                                    <div className="card-body pt-0">
                                      <div className="row text-center">
                                        <div className="mb-4 mb-lg-0">
                                          <div className="bg-image hover-zoom ripple rounded ripple-surface">
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_BUCKET_BASE_URL +
                                                item?.image
                                              }
                                              className="w-100"
                                              height="100px"
                                              style={{ objectFit: "contain" }}
                                              alt=""
                                            />
                                            <a href="#!">
                                              <div className="hover-overlay">
                                                <div
                                                  className="mask"
                                                  style={{
                                                    backgroundColor:
                                                      "rgba(253, 253, 253, 0.15)",
                                                  }}
                                                ></div>
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                        <div className="border-sm-start-none">
                                          <div className="d-flex flex-row align-items-center mb-1">
                                            <h5 className="mb-1 me-1 truncate-desc-one text-center w-100">
                                              {item.title}
                                            </h5>
                                          </div>
                                          <p className="mb-1 me-1 truncate-desc-one text-center w-100">
                                            {item.description}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="mb-5 mt-5">
                          <div className="row">
                            <h3 className="col-6">Decarbonization</h3>
                            <div className="col-6 d-flex justify-content-end">
                              <button
                                type="button"
                                className="btn btn-primary btn-block mb-4 px-5"
                                disabled={!isEdit}
                                onClick={() => {
                                  setAddNewDecarbon(true);
                                }}
                              >
                                Add
                              </button>
                            </div>
                            <ErrorMessage
                              name="decarbon"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                          <div className="row overflow-auto">
                            {decarbon &&
                              decarbon.length > 0 &&
                              decarbon.map((item, index) => (
                                <div
                                  className="col-md-6 col-lg-4 px-0"
                                  key={index}
                                >
                                  <div
                                    className="mx-2 card shadow-0 border rounded-3 mb-3"
                                    style={{
                                      boxShadow:
                                        "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                    }}
                                  >
                                    <div className="d-flex justify-content-end mt-2">
                                      <i
                                        className="mx-1 crud-button crud-primary crud-touch crud-edit text-primary"
                                        onClick={() => {
                                          setDecarbonInitialValues({
                                            title: item.title,
                                            desc: item.desc,
                                            imageUrl: [item.image],
                                            image: [],
                                          });

                                          setIsEditDecarbon(item?._id);
                                        }}
                                      ></i>
                                      <i
                                        class="mx-1 crud-button crud-primary crud-touch crud-delete text-danger"
                                        onClick={() => {
                                          setDeleteDecarbon(item?._id);
                                        }}
                                      ></i>
                                    </div>
                                    <div className="card-body pt-0">
                                      <div className="row text-center">
                                        <div className="mb-4 mb-lg-0">
                                          <div className="bg-image hover-zoom ripple rounded ripple-surface">
                                            <img
                                              src={
                                                process.env
                                                  .REACT_APP_BUCKET_BASE_URL +
                                                item?.image
                                              }
                                              className="w-100"
                                              height="100px"
                                              style={{ objectFit: "contain" }}
                                              alt=""
                                            />
                                            <a href="#!">
                                              <div className="hover-overlay">
                                                <div
                                                  className="mask"
                                                  style={{
                                                    backgroundColor:
                                                      "rgba(253, 253, 253, 0.15)",
                                                  }}
                                                ></div>
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                        <div className="border-sm-start-none">
                                          <div className="d-flex flex-row align-items-center mb-1">
                                            <h5 className="mb-1 me-1 truncate-desc-one text-center w-100">
                                              {item.title}
                                            </h5>
                                          </div>
                                          <p className="mb-1 me-1 truncate-desc-one text-center w-100">
                                            {item.description}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </Form>
                    </>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default CreateProduct;
