import React, { useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

const AuthModal = ({ setShowAuthModal }) => {
  const popupRef = useRef();

  const handleOutsideClick = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowAuthModal(false);
      }
    },
    [setShowAuthModal]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div className="share-overlay">
      <div
        style={{
          zIndex: "10000",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: " 90%",
          maxWidth: "500px",
        }}
        ref={popupRef}
      >
        <div class="modal-dialog">
          <div class="modal-content col-12">
            <div class="modal-header d-flex">
              <h5 class="modal-title">Authentication Required</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ border: "none", background: "none", fontSize: "28px" }}
                onClick={() => {
                  setShowAuthModal(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Login or create an account in to like a post</p>
            </div>
            <div class="modal-footer px-4 d-flex justify-content-center mb-3">
              <Link to="/login">
                <button
                  className="w-30 btn btn-lg btn-primary py-1 mx-2"
                  style={{ fontSize: "18px" }}
                >
                  Log in
                </button>
              </Link>
              <Link to="/signup">
                <button
                  className="w-30 btn btn-lg btn-primary py-1 mx-2"
                  style={{ fontSize: "18px" }}
                >
                  Sign Up
                </button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuthModal;
