import React, { useState } from "react";
import AdminSidebar from "../../../common/AdminSidebar";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Old Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
  newPassword: Yup.string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

const ChangePassword = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [loading, setLoadng] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setLoadng(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}auth/changePasswordAdmin`,
        values,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        setLoadng(false);
        resetForm();
        toast.success("Password changed successfully!");
      })
      .catch((error) => {
        setLoadng(false);
        toast.error(error.response.data.message);
        console.error("Error updating user data:", error);
      });
  };
  return (
    <div className="d-flex" style={{ height: "100vh", overflow: "hidden" }}>
      <AdminSidebar setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
      <section
        className="content"
        style={{
          width: "calc(100% -  220px)",
          overflow: "auto",
          maxHeight: "100vh",
          minHeight: "100vh",
        }}
      >
        <div className="container py-5">
          <div className="row justify-content-center mb-3">
            <div className="col-md-12 col-xl-10">
              <h2 className="mb-5">Change Password</h2>
              <Formik
                initialValues={{
                  oldPassword: "",
                  newPassword: "",
                  confirmPassword: "",
                }}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values, setValues, handleChange, handleBlur }) => (
                  <Form>
                    <div className="row mb-4" style={{ marginTop: "100px" }}>
                      <div className="col-md-12 col-lg-4">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="oldPassword">
                            Old Password
                          </label>
                          <input
                            type="password"
                            id="oldPassword"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="oldPassword"
                            value={values.oldPassword}
                          />
                          <ErrorMessage
                            name="oldPassword"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-4">
                        <div className="form-outline">
                          <label className="form-label" htmlFor="newPassword">
                            New Password
                          </label>
                          <input
                            type="password"
                            id="newPassword"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="newPassword"
                            value={values.newPassword}
                          />
                          <ErrorMessage
                            name="newPassword"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-lg-4">
                        <div className="form-outline">
                          <label
                            className="form-label"
                            htmlFor="confirmPassword"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            id="confirmPassword"
                            className="form-control"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="confirmPassword"
                            value={values.confirmPassword}
                          />
                          <ErrorMessage
                            name="confirmPassword"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center ">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block mb-4 w-25"
                        disabled={loading}
                      >
                        Update
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ChangePassword;
