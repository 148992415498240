import React, { useCallback, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

const CreateEditModal = ({
  isEdit,
  isEditAffiliate,
  setIsEditAffiliate,
  setAddNew,
  initialValues,
  setInitialValues,
  affiliateProducts,
  setAffiliateProducts,
  loading,
  setLoadng,
  id,
}) => {
  const popupRef = useRef();
  const [error, setError] = useState("");
  const handleClose = () => {
    setIsEditAffiliate("");
    setAddNew("");
    setInitialValues({
      title: "",
      link: "",
      image: [],
    });
  };

  const handleOutsideClick = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsEditAffiliate("");
        setAddNew("");
      }
    },
    [setIsEditAffiliate, setAddNew]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const handleMediaUpload = (event, values, setValues) => {
    const files = event.target.files;
    const selectedFiles = Array.from(files);

    const newSelectedImages = [];

    selectedFiles.forEach((file) => {
      if (file.type.startsWith("image/")) {
        newSelectedImages.push(file);
      }
    });
    setValues({
      ...values,
      imageUrl: [],
      image: [newSelectedImages[0]],
    });
  };

  const handleSubmit = async (values) => {
    console.log(values);
    // if (
    //   (isEdit &&
    //     values.imageUrl &&
    //     values.imageUrl.length === 0 &&
    //     values.image.length === 0) ||
    //   (!isEdit && isEditAffiliate !== "" && values.image.length === 0)
    // ) {
    //   setError("One image is required");
    // } else {
    setError("");

    if (isEditAffiliate !== "") {
      setLoadng(true);
      const formDataToSend = new FormData();
      formDataToSend.append("link", values.link);
      if (values.image[0]) {
        formDataToSend.append("image", values.image[0]);
      } else {
        formDataToSend.append("imageUrl", values.imageUrl);
      }
      formDataToSend.append("title", values.title);
      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}admin/product/updateAffiliateProduct/${id}/${isEditAffiliate}`,
          formDataToSend,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setAffiliateProducts(res.data.data.affiliateProducts);
          setLoadng(false);
          toast.success(res.data.message);
          handleClose();
        })
        .catch((error) => {
          setLoadng(false);
          toast.error(error.response.data.message);
        });
    } else {
      setLoadng(true);
      console.log(values);
      const formDataToSend = new FormData();
      formDataToSend.append("link", values.link);
      formDataToSend.append("image", values.image[0]);
      formDataToSend.append("title", values.title);
      await axios
        .post(
          `${process.env.REACT_APP_BASEURL}admin/product/addAffiliateProduct/${id}`,
          formDataToSend,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        )
        .then((res) => {
          setAffiliateProducts(res.data.data.affiliateProducts);
          setLoadng(false);
          toast.success(res.data.message);
          handleClose();
        })
        .catch((error) => {
          setLoadng(false);
          toast.error(error.response.data.message);
        });
    }
    // }
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    link: Yup.string().required("URL is required"),
    // image: Yup.array().min(1, "One image is required"),
  });

  return (
    <>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {({ values, setValues, handleChange, handleBlur }) => (
          <Form>
            <div className="row">
              <div className="share-overlay">
                <div
                  style={{
                    zIndex: "10000",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: " 90%",
                    maxWidth: "500px",
                  }}
                  ref={popupRef}
                >
                  <div class="modal-dialog">
                    <div class="modal-content col-12">
                      <div class="modal-header d-flex">
                        <h5 class="modal-title">
                          {isEditAffiliate !== ""
                            ? "Update Affiliate"
                            : "Add Affiliate"}
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          style={{
                            border: "none",
                            background: "none",
                            fontSize: "28px",
                          }}
                          onClick={() => {
                            setIsEditAffiliate("");
                            setAddNew("");
                            setInitialValues({
                              title: "",
                              link: "",
                              image: [],
                            });
                          }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div class="modal-body">
                        <div className="col">
                          <div className="form-outline mb-3">
                            <label className="form-label mb-0" htmlFor="Title">
                              Title
                            </label>
                            <input
                              type="text"
                              id="Title"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="title"
                              value={values.title}
                            />
                            <ErrorMessage
                              name="title"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-outline mb-3">
                            <label className="form-label mb-0" htmlFor="link">
                              URL
                            </label>
                            <input
                              type="link"
                              id="link"
                              className="form-control"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="link"
                              value={values.link}
                            />
                            <ErrorMessage
                              name="link"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-outline mb-3">
                            <label
                              className="form-label mb-0 w-100"
                              htmlFor="customFile"
                            >
                              Image
                              <span className="btn btn-primary btn-block mt-1 w-100">
                                {isEditAffiliate !== "" ? "Change" : "Upload"}{" "}
                                Image
                              </span>
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              id="customFile"
                              style={{ display: "none" }}
                              accept="image/*"
                              onChange={(e) => {
                                handleMediaUpload(e, values, setValues);
                                setError("");
                              }}
                            />
                            {error && (
                              <div className="error text-danger">{error}</div>
                            )}
                            <ErrorMessage
                              name="image"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                          <div className="d-flex justify-content-start">
                            {values.image &&
                              values.image.length > 0 &&
                              values.image.map((image, index) => (
                                <div
                                  className="position-relative d-flex justify-content-center mt-2 mx-1"
                                  style={{
                                    minWidth: "100px",
                                    boxShadow:
                                      "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  }}
                                  id="hover-btn"
                                >
                                  <div
                                    className="position-absolute w-100 h-100"
                                    id="icon"
                                  >
                                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                      <i
                                        className="fa fa-3x fa-times cursor-pointer icon-white "
                                        onClick={() => {
                                          setValues({
                                            ...values,
                                            image: values.image.filter(
                                              (item) => item !== image
                                            ),
                                          });
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                  <img
                                    key={`image-${index}`}
                                    src={URL.createObjectURL(image)}
                                    alt={""}
                                    height="100px"
                                    style={{ objectFit: "contain" }}
                                  />
                                </div>
                              ))}
                            {isEdit &&
                              values.imageUrl &&
                              values.imageUrl.length > 0 &&
                              values.imageUrl.map((url, index) => (
                                <div
                                  className="position-relative d-flex justify-content-center mt-2 mx-1 p-2"
                                  style={{
                                    minWidth: "100px",
                                    boxShadow:
                                      "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                  }}
                                  id="hover-btn"
                                >
                                  <div
                                    className="position-absolute w-100 h-100"
                                    id="icon"
                                  >
                                    <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                      <i
                                        className="fa fa-3x fa-times cursor-pointer icon-white "
                                        onClick={() => {
                                          setValues({
                                            ...values,
                                            imageUrl: values.imageUrl.filter(
                                              (item) => item !== url
                                            ),
                                          });
                                        }}
                                      ></i>
                                    </div>
                                  </div>
                                  <img
                                    key={`image-${index}`}
                                    src={
                                      process.env.REACT_APP_BUCKET_BASE_URL +
                                      url
                                    }
                                    alt={""}
                                    height="100px"
                                    style={{ objectFit: "contain" }}
                                  />
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                      <div class="modal-footer px-4 d-flex justify-content-center mb-3">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          style={{ marginTop: "33px" }}
                          disabled={loading}
                        >
                          Submit
                        </button>
                        <button
                          className="btn btn-secondary btn-block ms-2"
                          style={{ marginTop: "33px" }}
                          type="button"
                          onClick={() => {
                            setInitialValues({
                              title: "",
                              link: "",
                              image: [],
                            });
                            setIsEditAffiliate("");
                            setAddNew("");
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CreateEditModal;
