import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Appbar from "../../common/Appbar";

const validationSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .required("Old Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
  newPassword: Yup.string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
  confirmPassword: Yup.string()
    .required("Confirm Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character")
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
});

const ChangePassword = () => {
  const navigate = useNavigate();
  const [loading, setLoadng] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setLoadng(true);
    await axios
      .post(`${process.env.REACT_APP_BASEURL}auth/changePassword`, values, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setLoadng(false);
        resetForm();
        toast.success("Password changed successfully!");
      })
      .catch((error) => {
        setLoadng(false);
        toast.error(error.response.data.message);
        console.error("Error updating user data:", error);
      });
  };

  return (
    <>
      <Appbar />
      <div className="form-wrapper">
        <main className="form-signin mx-auto">
          <Formik
            initialValues={{
              oldPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="d-flex justify-content-center">
                <img className="mb-4 " src="assets/images/logo.svg"  width="100px" alt="" />
              </div>
              <div className="form-floating mt-3">
                <Field
                  type="password"
                  name="oldPassword"
                  className="form-control"
                />
                <ErrorMessage
                  name="oldPassword"
                  component="div"
                  className="error text-danger"
                />
                <label htmlFor="floatingInput ">Old Password</label>
              </div>
              <div className="form-floating mt-3">
                <Field
                  type="password"
                  name="newPassword"
                  className="form-control"
                />
                <ErrorMessage
                  name="newPassword"
                  component="div"
                  className="error text-danger"
                />
                <label htmlFor="floatingInput ">New Password</label>
              </div>
              <div className="form-floating mt-3">
                <Field
                  type="password"
                  name="confirmPassword"
                  className="form-control"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="error text-danger"
                />
                <label htmlFor="floatingPassword">Confirm Password</label>
              </div>

              <button
                className="w-100 btn btn-lg btn-primary mt-3"
                type="submit"
                disabled={loading}
              >
                Submit
              </button>
            </Form>
          </Formik>
        </main>
      </div>
    </>
  );
};

export default ChangePassword;
