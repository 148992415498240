import React, { useCallback, useEffect, useRef, useState } from "react";
import { ErrorMessage, Formik, Form } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

const Video = ({
  isEditForm,
  isEdit,
  setIsEdit,
  addNew,
  setAddNew,
  productValues,
  setValues,
  initialValues,
  setInitialValues,
  loading,
  setLoadng,
  id,
}) => {
  const popupRef = useRef();
  const [error, setError] = useState("");
  const handleClose = () => {
    setIsEdit("");
    setAddNew("");
    setInitialValues({
      title: "",
      location: "",
      productionDate: "",
      video: [],
      thumbnail: [],
    });
  };

  const handleOutsideClick = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsEdit("");
        setAddNew("");
      }
    },
    [setIsEdit, setAddNew]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  const handleVideoUpload = (event, values, setValues) => {
    const files = event.target.files;
    const selectedFiles = Array.from(files);

    const newSelectedVideos = [];

    selectedFiles.forEach((file) => {
      if (file.type.startsWith("video/")) {
        newSelectedVideos.push(file);
      }
    });

    setValues({
      ...values,
      video: [...newSelectedVideos],
    });
  };

  const handleImageUpload = (event, values, setValues) => {
    const files = event.target.files;
    const selectedFiles = Array.from(files);

    const newSelectedImages = [];
    selectedFiles.forEach((file) => {
      if (file.type.startsWith("image/")) {
        newSelectedImages.push(file);
      }
    });
    console.log(newSelectedImages);
    setValues({
      ...values,
      thumbnail: [...newSelectedImages],
    });
  };

  const handleSubmit = async (values) => {
    // if (
    //   (isEditForm &&
    //     values.videoUrl &&
    //     values.videoUrl.length === 0 &&
    //     values.video.length === 0) ||
    //   (!isEditForm && isEdit !== "" && values.video.length === 0)
    // ) {
    //   setError("One video is required");
    // } else {
    setError("");
    if (isEditForm) {
      if (addNew) {
        setLoadng(true);
        console.log(values);
        const formDataToSend = new FormData();
        formDataToSend.append("location", values.location);
        formDataToSend.append("id", values.id);
        formDataToSend.append("productionDate", values.productionDate);
        formDataToSend.append("title", values.title);
        formDataToSend.append("video", values.video[0]);
        formDataToSend.append("thumbnail", values.thumbnail[0]);
        await axios
          .post(
            `${process.env.REACT_APP_BASEURL}admin/product/addProductVideo/${id}`,
            formDataToSend,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            setValues({
              ...productValues,
              video: res.data.data.videos,
            });
            setLoadng(false);
            toast.success(res.data.message);
            handleClose();
          })
          .catch((error) => {
            setLoadng(false);
            toast.error(error.response.data.message);
          });
      } else {
        setLoadng(true);
        console.log(values);
        const formDataToSend = new FormData();
        formDataToSend.append("location", values.location);
        formDataToSend.append("productionDate", values.productionDate);
        formDataToSend.append("title", values.title);
        formDataToSend.append("id", values.id);
        if (typeof values.video[0] === "object") {
          formDataToSend.append("video", values.video[0]);
        } else {
          formDataToSend.append("videoUrl", values.video);
        }
        if (typeof values.thumbnail[0] === "object") {
          formDataToSend.append("thumbnail", values.thumbnail[0]);
        } else {
          formDataToSend.append("thumbnailUrl", values.thumbnail);
        }
        await axios
          .post(
            `${process.env.REACT_APP_BASEURL}admin/product/updateProductVideo/${id}/${isEdit}`,
            formDataToSend,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
          .then((res) => {
            setValues({
              ...productValues,
              video: res.data.data.videos,
            });
            setLoadng(false);
            toast.success(res.data.message);
            handleClose();
          })
          .catch((error) => {
            setLoadng(false);
            toast.error(error.response.data.message);
          });
      }
    } else if (isEdit !== "") {
      setValues({
        ...productValues,
        video: productValues.video.map((item, index) =>
          index === isEdit ? values : item
        ),
      });
      handleClose();
    } else {
      setValues({
        ...productValues,
        video: [...productValues.video, values],
      });
      handleClose();
    }
    // }
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    location: Yup.string().required("Location is required"),
    productionDate: Yup.string().required("Production Date is required."),
    // video:
    //   isEdit && initialValues.videoUrl.length > 0
    //     ? Yup.array()
    //     : isEdit !== ""
    //     ? Yup.array()
    //     : Yup.array().min(1, "One video is required"),
  });

  return (
    <>
      <Formik
        initialValues={{ ...initialValues }}
        validationSchema={validationSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values, resetForm);
        }}
      >
        {({ values, setValues, handleChange, handleBlur }) => (
          <Form>
            <div className="row">
              <div className="share-overlay">
                <div
                  style={{
                    zIndex: "10000",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: " 90%",
                    maxWidth: "500px",
                  }}
                  ref={popupRef}
                >
                  <div class="modal-dialog">
                    <div class="modal-content col-12">
                      <div class="modal-header d-flex">
                        <h5 class="modal-title">
                          {isEdit !== "" ? "Update Video" : "Add Video"}
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          style={{
                            border: "none",
                            background: "none",
                            fontSize: "28px",
                          }}
                          onClick={() => {
                            setIsEdit("");
                            setAddNew("");
                            setInitialValues({
                              title: "",
                              location: "",
                              productionDate: "",
                              video: [],
                            });
                          }}
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div style={{ overflow: "auto", maxHeight: "80vh" }}>
                        <div class="modal-body">
                          <div className="col">
                            <div className="form-outline mb-3">
                              <label
                                className="form-label mb-0"
                                htmlFor="Title"
                              >
                                Title
                              </label>
                              <input
                                type="text"
                                id="Title"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="title"
                                value={values.title}
                              />
                              <ErrorMessage
                                name="title"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-outline mb-3">
                              <label
                                className="form-label mb-0"
                                htmlFor="location"
                              >
                                Location
                              </label>
                              <input
                                type="text"
                                id="location"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="location"
                                value={values.location}
                              />
                              <ErrorMessage
                                name="location"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-outline mb-3">
                              <label
                                className="form-label mb-0"
                                htmlFor="productionDate"
                              >
                                Production Date
                              </label>
                              <input
                                type="text"
                                id="productionDate"
                                className="form-control"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                name="productionDate"
                                value={values.productionDate}
                              />
                              <ErrorMessage
                                name="productionDate"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-outline mb-3">
                              <label
                                className="form-label mb-0 w-100"
                                htmlFor="customFilevideo"
                              >
                                Video
                                <span className="btn btn-primary btn-block mt-1 w-100">
                                  {isEdit !== "" ? "Change" : "Upload"} Video
                                </span>
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                id="customFilevideo"
                                accept="video/*"
                                multiple={false}
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  handleVideoUpload(e, values, setValues);
                                }}
                              />
                              {error && (
                                <div className="error text-danger">{error}</div>
                              )}
                              <ErrorMessage
                                name="video"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                            <div className="d-flex justify-content-start">
                              {values.video &&
                                values.video.length > 0 &&
                                values.video.map((video, index) => (
                                  <div
                                    className="position-relative d-flex justify-content-center align-items-center mt-2 col-5 mx-1"
                                    id="hover-btn"
                                    style={{
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    }}
                                  >
                                    <div
                                      className="position-absolute w-100 h-100"
                                      id="icon"
                                    >
                                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                        <i
                                          className="fa fa-3x fa-times cursor-pointer icon-white "
                                          onClick={() => {
                                            setValues({
                                              ...values,
                                              video: values.video.filter(
                                                (item) => item !== video
                                              ),
                                            });
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                    <video
                                      key={`video-${index}`}
                                      src={
                                        typeof video === "object"
                                          ? URL.createObjectURL(video)
                                          : process.env
                                              .REACT_APP_BUCKET_BASE_URL + video
                                      }
                                      controls
                                      height="150px"
                                      width="100%"
                                      style={{ objectFit: "contain" }}
                                    >
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </div>
                                ))}
                              {isEdit &&
                                values.videoUrl &&
                                values.videoUrl.length > 0 &&
                                values.videoUrl.map((url, index) => (
                                  <div
                                    className="position-relative d-flex justify-content-center align-items-center mt-2 col-5 mx-1"
                                    id="hover-btn"
                                    style={{
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    }}
                                  >
                                    <div
                                      className="position-absolute w-100 h-100"
                                      id="icon"
                                    >
                                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                        <i
                                          className="fa fa-3x fa-times cursor-pointer icon-white "
                                          onClick={() => {
                                            setValues({
                                              ...values,
                                              videoUrl: values.videoUrl.filter(
                                                (item) => item !== url
                                              ),
                                            });
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                    <video
                                      key={`video-${index}`}
                                      src={
                                        process.env.REACT_APP_BUCKET_BASE_URL +
                                        url
                                      }
                                      controls
                                      height="150px"
                                      width="100%"
                                      style={{ objectFit: "contain" }}
                                    >
                                      Your browser does not support the video
                                      tag.
                                    </video>
                                  </div>
                                ))}
                            </div>
                          </div>
                          <div className="col">
                            <div className="form-outline mb-3">
                              <label
                                className="form-label mb-0 w-100"
                                htmlFor="customFileimage"
                              >
                                Thumbnail
                                <span className="btn btn-primary btn-block mt-1 w-100">
                                  {isEdit !== "" ? "Change" : "Upload"}{" "}
                                  Thumbnail
                                </span>
                              </label>
                              <input
                                type="file"
                                className="form-control"
                                id="customFileimage"
                                accept="image/*"
                                multiple={false}
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  handleImageUpload(e, values, setValues);
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-start">
                              {values.thumbnail &&
                                values.thumbnail.length > 0 &&
                                values.thumbnail.map((video, index) => (
                                  <div
                                    className="position-relative d-flex justify-content-center align-items-center mt-2 col-5 mx-1"
                                    id="hover-btn"
                                    style={{
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    }}
                                  >
                                    <div
                                      className="position-absolute w-100 h-100"
                                      id="icon"
                                    >
                                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                        <i
                                          className="fa fa-3x fa-times cursor-pointer icon-white "
                                          onClick={() => {
                                            setValues({
                                              ...values,
                                              thumbnail:
                                                values.thumbnail.filter(
                                                  (item) => item !== video
                                                ),
                                            });
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                    <img
                                      key={`video-${index}`}
                                      src={
                                        typeof video === "object"
                                          ? URL.createObjectURL(video)
                                          : process.env
                                              .REACT_APP_BUCKET_BASE_URL + video
                                      }
                                      height="150px"
                                      width="100%"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </div>
                                ))}
                              {isEdit &&
                                values.thumbnailUrl &&
                                values.thumbnailUrl.length > 0 &&
                                values.thumbnailUrl.map((url, index) => (
                                  <div
                                    className="position-relative d-flex justify-content-center align-items-center mt-2 col-5 mx-1"
                                    id="hover-btn"
                                    style={{
                                      boxShadow:
                                        "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                    }}
                                  >
                                    <div
                                      className="position-absolute w-100 h-100"
                                      id="icon"
                                    >
                                      <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                                        <i
                                          className="fa fa-3x fa-times cursor-pointer icon-white "
                                          onClick={() => {
                                            setValues({
                                              ...values,
                                              thumbnailUrl:
                                                values.thumbnailUrl.filter(
                                                  (item) => item !== url
                                                ),
                                            });
                                          }}
                                        ></i>
                                      </div>
                                    </div>
                                    <img
                                      key={`video-${index}`}
                                      src={
                                        process.env.REACT_APP_BUCKET_BASE_URL +
                                        url
                                      }
                                      height="150px"
                                      width="100%"
                                      style={{ objectFit: "contain" }}
                                    />
                                  </div>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer px-4 d-flex justify-content-center mb-3">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                            style={{ marginTop: "33px" }}
                            disabled={loading}
                          >
                            Submit
                          </button>
                          <button
                            className="btn btn-secondary btn-block ms-2"
                            style={{ marginTop: "33px" }}
                            type="button"
                            onClick={() => {
                              setInitialValues({
                                title: "",
                                location: "",
                                productionDate: "",
                                video: [],
                              });
                              setIsEdit("");
                              setAddNew("");
                            }}
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default Video;
