import React from "react";
import { ThreeDots } from "react-loader-spinner";

const OverlayLoader = ({ isFullWidth }) => {
  return (
    <div
      className={
        isFullWidth ? "loader-overlay full-width-overlay" : "loader-overlay"
      }
    >
      <ThreeDots
        height="40"
        width="80"
        radius="9"
        color="#3D83F5"
        ariaLabel="three-dots-loading"
        wrapperStyle={{
          display: "flex",
          justifyContent: "center",
        }}
        wrapperClassName="spinner"
        visible={true}
      />
    </div>
  );
};

export default OverlayLoader;
