import React, { useCallback, useEffect } from "react";

const AffiliateDropDown = ({ key, item, setShowAffiliate, popupRef }) => {
  const handleOutsideClick = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowAffiliate(false);
      }
    },
    [setShowAffiliate, popupRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div className="shop_item" key={key}>
      <div className="content">
        <h6 className="f-20">{item.title}</h6>{" "}
        <img
          src={process.env.REACT_APP_BUCKET_BASE_URL + item.image}
          alt="coors"
          className="img-fluid"
          style={{ width: "50px", height: "50px", objectFit: "contain" }}
        />{" "}
      </div>
      <a href={item.link} target="_blank" rel="noreferrer">
        <button className="themeBtn-blue">Shop</button>
      </a>
    </div>
  );
};

export default AffiliateDropDown;
