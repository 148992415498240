import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoutes = (props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  let Component = props.component;

  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else if (user?.role !== props.role) {
      if (user.role !== "admin") {
        navigate("/login");
      }
    }
  }, []);
  return (
    <>
      {token &&
        (user?.role === "admin" || user?.role === props.role) &&
        Component}
    </>
  );
};
export default ProtectedRoutes;
