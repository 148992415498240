import React, { useEffect, useState } from "react";
import Appbar from "../../common/Appbar";
import axios from "axios";
import { toast } from "react-toastify";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";

const Favourites = () => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASEURL}user/likeVideo/allLikes`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setVideos(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
        });
    };
    fetchData();
  }, []);
  return (
    <div>
      <Appbar />
      {loading ? (
        <ThreeDots
          height="40"
          width="80"
          radius="9"
          color="#3D83F5"
          ariaLabel="three-dots-loading"
          wrapperStyle={{
            display: "flex",
            justifyContent: "center",
            marginTop: "150px",
          }}
          wrapperClassName="spinner"
          visible={true}
        />
      ) : (
        <section>
          <div className="container mt-5 pt-5">
            <div className="row justify-content-center">
              <div className="col-md-12 col-xl-10">
                {videos && videos.length > 0 ? (
                  <>
                    <h2 className="text-center" style={{ marginBottom: "0px" }}>
                      Favourites
                    </h2>
                    <section className="vedio_main">
                      <div className="container">
                        <div className="vedio_main_inner">
                          <div className="row">
                            {videos.map((video, index) => (
                              <div
                                className="col-md-4 gap-2"
                              >
                                <div
                                  className={`vedio_wrap  before_none after_none
                                  `}
                                >
                                  <div className={` before_none after_none`}>
                                    <Link
                                      to={`/favourite-videos?id=${video.product._id}&videoId=${video.product.videos._id}`}
                                    >
                                      <div className="about-video-wrapper">
                                        {video?.thumbnail ? (
                                          <img
                                            id="videoPlayer"
                                            style={{
                                              objectFit: "cover",
                                              width: "100%",
                                              height: "100%",
                                            }}
                                            src={
                                              process.env
                                                .REACT_APP_BUCKET_BASE_URL +
                                              video.thumbnail
                                            }
                                          />
                                        ) : (
                                          <video
                                            id="videoPlayer"
                                            preload="metadata"
                                            muted={true}
                                            controls={false}
                                            style={{ objectFit: "cover" }}
                                            src={
                                              process.env
                                                .REACT_APP_BUCKET_BASE_URL +
                                              video.product.videos.video +
                                              "#t=0.001"
                                            }
                                          ></video>
                                        )}
                                        <div className="vedio_footer d-flex align-items-end justify-content-between">
                                          <h1
                                            class="f-23"
                                            style={{
                                              color: "white !important",
                                            }}
                                          >
                                            {video.product.videos.title}
                                          </h1>
                                          <img
                                            src="assets/images/right_circle.png"
                                            alt="right_circle"
                                            className="img-fluid"
                                          />{" "}
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                  <h5 class="f-23">
                                    <span class="d-blcok">
                                      {video.product.videos.productionDate}
                                    </span>
                                    {video.product.videos.location}
                                  </h5>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </section>
                  </>
                ) : (
                  <h2 className="text-center" style={{ marginBottom: "0px" }}>
                    No Favourites Found
                  </h2>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

export default Favourites;
