import { BrowserRouter, Routes, Route } from "react-router-dom";
import ProtectedRoutes from "./common/ProtectedRoutes";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import ChangePasswordUser from "./pages/ChangePassword";
import ChangePassword from "./pages/Dashboard/ChangePassword";
import Home from "./pages/Home";
import Video from "./pages/Video";
import AllProducts from "./pages/Dashboard/AllProducts";
import CreateProduct from "./pages/Dashboard/CreateProduct";
import ProductDetail from "./pages/ProductDetail";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Favourites from "./pages/Favourites";
import FavouritesVideos from "./pages/FavouritesVideos";

function App() {
  const navigate = useNavigate();
  const isTokenExpired = (token) => {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;
    return decodedToken.exp < currentTime;
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && isTokenExpired(token)) {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      navigate("/login");
    }
  }, []);
  return (
    <Routes>
      {/* Auth Routes */}
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/signup" element={<Signup />} />

      {/* User routes */}
      <Route exact path="/" element={<Home />} />
      <Route exact path="/product" element={<ProductDetail />} />
      <Route path="/video" element={<Video />} />
      <Route
        path="/favourites"
        element={<ProtectedRoutes role="user" component={<Favourites />} />}
      />
      <Route
        path="/favourite-videos"
        element={<ProtectedRoutes role="user" component={<FavouritesVideos />} />}
      />
      
      <Route
        path="/change-password"
        element={<ProtectedRoutes role="user" component={<ChangePasswordUser  key="2" />} />}
      />
       <Route
        path="/update-password"
        element={<ProtectedRoutes role="admin"  component={<ChangePassword key="1" />} />}
      />

      <Route
        path="/create-product"
        element={
          <ProtectedRoutes
            role="admin"
            component={<CreateProduct key="add" />}
          />
        }
      />
      <Route
        path="/edit-product"
        element={
          <ProtectedRoutes
            role="admin"
            component={<CreateProduct key="edit" />}
          />
        }
      />
      <Route
        path="/all-products"
        element={<ProtectedRoutes role="admin" component={<AllProducts />} />}
      />
    </Routes>
  );
}

export default App;
