import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("First Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required")
    .min(8, "Password must be at least 8 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase character"),
});

const Signup = () => {
  const navigate = useNavigate();
  const [loading, setLoadng] = useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setLoadng(true);
    await axios
      .post(`${process.env.REACT_APP_BASEURL}auth/register`, values)
      .then((res) => {
        setLoadng(false);
        resetForm();
        localStorage.setItem("token", res.data.token);
        localStorage.setItem("user", JSON.stringify(res.data.data));
        if (res.data.data.role === "admin") {
          navigate("/all-products");
        } else {
          navigate("/");
        }
      })
      .catch((error) => {
        setLoadng(false);
        toast.error(error.response.data.message);
        console.error("Error updating user data:", error);
      });
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token, navigate]);

  return (
    !token && (
      <div className="form-wrapper">
        <main className="form-signin mx-auto">
          <Formik
            initialValues={{
              name: "",
              email: "",
              password: "",
              confirmPassword: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            <Form>
              <div className="d-flex justify-content-center">
                <Link to="/">
                  <img
                    className="mb-4 "
                    width="100px"
                    src="assets/images/logo.svg"
                    alt=""
                  />
                </Link>
              </div>

              <div className="form-floating mt-3">
                <Field type="text" name="name" className="form-control" />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="error text-danger"
                />
                <label htmlFor="floatingInput ">Name</label>
              </div>
              <div className="form-floating mt-3">
                <Field type="email" name="email" className="form-control" />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error text-danger"
                />
                <label htmlFor="floatingInput ">Email address</label>
              </div>
              <div className="form-floating mt-3">
                <Field
                  type="password"
                  name="password"
                  className="form-control"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="error text-danger"
                />
                <label htmlFor="floatingPassword">Password</label>
              </div>
              <div className="form-floating mt-3">
                <Field
                  type="password"
                  name="confirmPassword"
                  className="form-control"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="error text-danger"
                />
                <label htmlFor="floatingPassword">Confirm Password</label>
              </div>

              <button
                className="w-100 btn btn-lg btn-primary mt-3"
                type="submit"
                disabled={loading}
              >
                Sign up
              </button>
              <p className="text-center mt-3">
                Already have an account?{" "}
                <Link to="/login" className="text-primary">
                  Login
                </Link>
              </p>
            </Form>
          </Formik>
        </main>
      </div>
    )
  );
};

export default Signup;
