import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AdminSidebar from "../../../common/AdminSidebar";
import { ThreeDots } from "react-loader-spinner";
import { Link } from "react-router-dom";
import DeleteConfirmationModal from "../../../common/DeleteConfirmationModal";

const AllProducts = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_BASEURL}admin/product/viewAll`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          setProducts(res.data.data);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message);
          setLoading(false);
        });
    };
    fetchData();
  }, []);

  const handleDelete = async (id) => {
    setDeleteLoading(true);
    await axios
      .get(`${process.env.REACT_APP_BASEURL}admin/product/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setProducts(products.filter((item) => item._id !== id));
        toast.success("Product deleted successfully!");
        setDeleteLoading(false);
        setDeleteModal("");
      })
      .catch((err) => {
        toast.error(err.response.data.message);
        setDeleteLoading(false);
        setDeleteModal("");
      });
  };

  return (
    <div className="d-flex">
      <AdminSidebar setShowSidebar={setShowSidebar} showSidebar={showSidebar} />
      {deleteModal && (
        <DeleteConfirmationModal
          setDeleteModal={setDeleteModal}
          disabled={deleteLoading}
          handleSubmit={() => {
            handleDelete(deleteModal);
          }}
        />
      )}
      <section
        className="content"
        style={{
          width: "calc(100% -  220px)",
          overflow: "auto",
          maxHeight: "100vh",
          minHeight: "100vh",
        }}
      >
        <div className="container pt-5">
          <div className="row justify-content-center">
            <div className="col-md-12 col-xl-10">
              <h2 className="mb-5">All Products</h2>
              <div className="row overflow-auto">
                {loading ? (
                  <ThreeDots
                    height="40"
                    width="80"
                    radius="9"
                    color="#3D83F5"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                    wrapperClassName="spinner"
                    visible={true}
                  />
                ) : products && products.length > 0 ? (
                  products.map((item, index) => (
                    <div className="col-md-6 col-lg-4" key={index}>
                      <div
                        className="card shadow-0 border rounded-3 mb-3"
                        style={{
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                        }}
                      >
                        <div className="d-flex justify-content-end mt-2">
                          <Link to={`/edit-product?edit=true&id=${item._id}`}>
                            <i className="mx-1 crud-button crud-primary crud-touch crud-edit text-primary"></i>
                          </Link>
                          <i
                            class="mx-1 crud-button crud-primary crud-touch crud-delete text-danger"
                            onClick={() => {
                              setDeleteModal(item._id);
                            }}
                          ></i>
                        </div>
                        <div className="card-body pt-0">
                          <div className="row">
                            <div className=" mb-4 mb-lg-0">
                              <div className="bg-image text-center hover-zoom ripple rounded ripple-surface">
                                <img
                                  src={
                                    process.env.REACT_APP_BUCKET_BASE_URL +
                                    item?.image
                                  }
                                  className="w-100"
                                  height="100px"
                                  style={{ objectFit: "contain" }}
                                  alt=""
                                />
                                <a href="#!">
                                  <div className="hover-overlay">
                                    <div
                                      className="mask"
                                      style={{
                                        backgroundColor:
                                          "rgba(253, 253, 253, 0.15)",
                                      }}
                                    ></div>
                                  </div>
                                </a>
                              </div>
                            </div>
                            <div className="">
                              <h5 className="mt-2 truncate-desc-one">
                                {item.name}
                              </h5>
                              <p
                                className="mt-2 mb-4 mb-md-0 truncate-desc "
                                style={{ minHeight: "50px" }}
                              >
                                {item.description}
                              </p>
                            </div>
                            <div className="mt-2">
                              <div className="d-flex flex-row align-items-center mb-1">
                                <h5 className="mb-1 me-1">${item.price}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h4 className="text-danger text-center">No Products</h4>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default AllProducts;
