import { useEffect, useRef, useState } from 'react';

const useIntersectionObserver = (options = {}) => {
  const { root = null, rootMargin = '0px 0px 0px 0px', threshold = 0, index } = options;
  const isClient = typeof window === 'object';
  const [entry, updateEntry] = useState({});
  const [node, setNode] = useState(null);
  let observer = null;

  observer = useRef(
    isClient
      ? new window.IntersectionObserver(
          ([intersectionEntry]) => updateEntry(intersectionEntry),
          {
            root,
            rootMargin,
            threshold,
          }
        )
      : null
  );

  const unObserve = () => {
    const { current: currentObserver } = observer;
    if (currentObserver) {
      currentObserver.disconnect();
    }
  };

  useEffect(() => {
    const { current: currentObserver } = observer;

    if (!isClient || !currentObserver) {
      return;
    }

    currentObserver.disconnect();

    if (node) {
      currentObserver.observe(node);
    }

    return () => currentObserver.disconnect();
  }, [isClient, node, index]);

  const setNodeRef = (ref) => {
    setNode(ref);

    const { current: currentObserver } = observer;
    if (currentObserver && ref) {
      currentObserver.observe(ref);
    }
  };

  return [setNodeRef, entry, unObserve];
};

export default useIntersectionObserver;