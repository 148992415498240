import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Appbar = () => {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = useState(
    JSON.parse(localStorage.getItem("user"))?.role === "admin"
  );
  const [isLoggedIn, setIsLoggedIn] = useState(localStorage.getItem("token"));
  return (
    <header className=" site-header meanu-header">
      <nav className="navbar bg-white fixed-top">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img
              src="assets/images/logo.svg"
              alt="logo"
              width="100px"
              className="img-fluid"
            />
          </Link>
          <div className="search ml-auto">
            {isAdmin && (
              <Link to="/all-products" className="text-primary pt-2 mx-2">
                Dashboard
              </Link>
            )}
            {isLoggedIn ? (
              <span>
                <i
                  className="fa fa-sign-out fa-lg text-primary mx-1 ms-3"
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                    setIsAdmin("");
                    setIsLoggedIn("");
                    navigate("/login");
                  }}
                ></i>
              </span>
            ) : (
              <span className="mx-1 ms-2">
                <Link to="/login" className="text-primary">
                  Login
                </Link>
              </span>
            )}
          </div>
          <div className="icon_num">
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasNavbar"
              aria-controls="offcanvasNavbar"
            >
              {" "}
              <img src="assets/images/hamurger.svg" alt="logo" />{" "}
            </button>
          </div>
          <div
            className="offcanvas offcanvas-start"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header d-flex justify-content-end">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">
                <li
                  className="nav-item"
                  type="button"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  {" "}
                  <Link className="nav-link" to="/">
                    Home
                  </Link>{" "}
                </li>
                {isLoggedIn && !isAdmin && (
                  <li
                    className="nav-item"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    {" "}
                    <div className="icon_num">
                      <Link className="nav-link" to="/favourites">
                        My Favourites
                      </Link>
                    </div>{" "}
                  </li>
                )}
                {isLoggedIn && !isAdmin && (
                  <li
                    className="nav-item"
                    type="button"
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  >
                    {" "}
                    <div className="icon_num">
                      <Link className="nav-link" to="/change-password">
                        Change Password
                      </Link>
                    </div>{" "}
                  </li>
                )}

                <li
                  className="nav-item"
                  type="button"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  {" "}
                  <a className="nav-link" href="#">
                    Contact Sales
                  </a>{" "}
                </li>
                <li
                  className="nav-item"
                  type="button"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  {" "}
                  <a className="nav-link" href="#">
                    Contact Support
                  </a>{" "}
                </li>
                <li
                  className="nav-item"
                  type="button"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  {" "}
                  <a className="nav-link" href="#">
                    Privacy
                  </a>{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Appbar;
