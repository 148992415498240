import React, { useCallback, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./AdminSidebar.css";

const AdminSidebar = ({ setShowSidebar, showSidebar }) => {
  const popupRef = useRef();

  const handleOutsideClick = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowSidebar(false);
      }
    },
    [setShowSidebar]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);
  return (
    <>
      <div
        className="mobileCollapse"
        style={{
          background: "white",
          position: "absolute",
          zIndex: "1",
          width: "100%",
        }}
      >
        <div
          style={{ margin: "10px" }}
          onClick={() => {
            setShowSidebar(true);
          }}
        >
          <i class="fa fa-lg fa-bars" aria-hidden="true"></i>
        </div>
      </div>
      <div
        className={showSidebar ? "main showSidebar" : "main hideSidebar"}
        style={{ minHeight: "100vh" }}
      >
        <aside className="d-flex" style={{ minHeight: "100vh" }}>
          <div
            className="sidebar left "
            style={{ minHeight: "100vh" }}
            ref={popupRef}
          >
            <div style={{ backgroundColor: "#222" }}>
              <div
                className="justify-content-end mobileCollapse"
                style={{ margin: "10px" }}
                onClick={() => {
                  setShowSidebar(false);
                }}
              >
                <i
                  style={{ color: "white" }}
                  class="fa fa-lg fa-times"
                  aria-hidden="true"
                ></i>
              </div>
              <div className="d-flex justify-content-center pt-4 pb-2">
                <Link to="/">
                  <img
                    className="mb-4 "
                    width="100px"
                    src="assets/images/logo.svg"
                    alt=""
                  />
                </Link>
              </div>
            </div>
            <ul className="list-sidebar bg-defoult">
              <li>
                <Link
                  to="/all-products"
                  onClick={() => {
                    setShowSidebar(false);
                  }}
                >
                  <i className="fa fa-th-large"></i>{" "}
                  <span className="nav-label"> Products </span>{" "}
                </Link>
              </li>
              <li>
                <Link
                  to="/create-product"
                  onClick={() => {
                    setShowSidebar(false);
                  }}
                >
                  <i className="fa fa-plus"></i>{" "}
                  <span className="nav-label">Add Product</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/update-password"
                  onClick={() => {
                    setShowSidebar(false);
                  }}
                >
                  <i className="fa fa-key"></i>{" "}
                  <span className="nav-label">Change Password</span>
                </Link>
              </li>
              <li>
                <Link
                  to="/login"
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user");
                  }}
                >
                  <i className="fa fa-sign-out"></i>{" "}
                  <span className="nav-label">Logout</span>{" "}
                </Link>
              </li>
            </ul>
          </div>
        </aside>
      </div>
    </>
  );
};

export default AdminSidebar;
