import React, { useCallback, useEffect, useRef } from "react";

const DeleteConfirmationModal = ({
  setDeleteModal,
  handleSubmit,
  disabled,
}) => {
  const popupRef = useRef();

  const handleOutsideClick = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setDeleteModal("");
      }
    },
    [setDeleteModal]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div className="share-overlay">
      <div
        style={{
          zIndex: "10000",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: " 90%",
          maxWidth: "500px",
        }}
        ref={popupRef}
      >
        <div class="modal-dialog">
          <div class="modal-content col-12">
            <div class="modal-header d-flex">
              <h5 class="modal-title">Delete Confirmation</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ border: "none", background: "none", fontSize: "28px" }}
                onClick={() => {
                  setDeleteModal("");
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>Do you really want to delete this product permanently?</p>
            </div>
            <div class="modal-footer px-4 d-flex justify-content-center mb-3">
              <button
                className="w-30 btn btn-lg btn-danger py-1 mx-2"
                style={{ fontSize: "18px" }}
                onClick={handleSubmit}
                disabled={disabled}
              >
                Delete
              </button>

              <button
                className="w-30 btn btn-lg btn-secondary py-1 mx-2"
                style={{ fontSize: "18px" }}
                onClick={() => {
                  setDeleteModal("");
                }}
                disabled={disabled}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmationModal;
