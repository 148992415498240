import React, { useCallback, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
  RedditShareButton,
  TelegramShareButton,
  WhatsappShareButton,
} from "react-share";

const SocialShare = ({ setShareModal, handleShare, shareLoading }) => {
  const popupRef = useRef();
  const shareLink = window.location.href;
  const handleCopy = async () => {
    await navigator.clipboard.writeText(shareLink);
    toast.success("URL copied");
  };

  const handleOutsideClick = useCallback(
    (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShareModal(false);
      }
    },
    [setShareModal]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [handleOutsideClick]);

  return (
    <div className="share-overlay">
      <div
        style={{
          zIndex: "10000",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: " 90%",
          maxWidth: "500px",
        }}
        ref={popupRef}
      >
        <div class="modal-dialog">
          <div class="modal-content col-12">
            <div class="modal-header d-flex">
              <h5 class="modal-title">Share</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                style={{ border: "none", background: "none", fontSize: "28px" }}
                onClick={() => {
                  setShareModal(false);
                }}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="icon-container1 d-flex flex-wrap justify-content-center">
                <LinkedinShareButton url={shareLink} onClick={handleShare}>
                  <div class="smd">
                    <i
                      class="img-thumbnail fa fa-linkedin fa-2x"
                      style={{ color: "#4c6ef5", backgroundColor: "aliceblue" }}
                    ></i>
                    <p>Linkedin</p>
                  </div>
                </LinkedinShareButton>
                <TwitterShareButton url={shareLink} onClick={handleShare}>
                  <div class="smd">
                    <i
                      class="img-thumbnail fa fa-twitter fa-2x"
                      style={{ color: "#4c6ef5", backgroundColor: "aliceblue" }}
                    ></i>
                    <p>Twitter</p>
                  </div>
                </TwitterShareButton>
                <FacebookShareButton url={shareLink} onClick={handleShare}>
                  <div class="smd">
                    <i
                      class="img-thumbnail fa fa-facebook fa-2x"
                      style={{ color: "#3b5998", backgroundColor: "#eceff5" }}
                    ></i>
                    <p>Facebook</p>
                  </div>
                </FacebookShareButton>
                <RedditShareButton url={shareLink} onClick={handleShare}>
                  <div class="smd">
                    <i
                      class="img-thumbnail fa fa-reddit-alien fa-2x"
                      style={{ color: "#ff5700", backgroundColor: "#fdd9ce" }}
                    ></i>
                    <p>Reddit</p>
                  </div>
                </RedditShareButton>
                <WhatsappShareButton url={shareLink} onClick={handleShare}>
                  <div class="smd">
                    <i
                      class="img-thumbnail fa fa-whatsapp fa-2x"
                      style={{ color: "#25d366", backgroundColor: "#cef5dc" }}
                    ></i>
                    <p>Whatsapp</p>
                  </div>
                </WhatsappShareButton>
                <TelegramShareButton url={shareLink} onClick={handleShare}>
                  <div class="smd">
                    <i
                      class="img-thumbnail fa fa-telegram fa-2x"
                      style={{ color: "#4c6ef5", backgroundColor: "aliceblue" }}
                    ></i>
                    <p>Telegram</p>
                  </div>
                </TelegramShareButton>
              </div>
            </div>
            <div class="modal-footer px-4">
              <label style={{ fontWeight: "600" }}>
                Video Link <span class="message"></span>
              </label>
              <br />
              <div class="d-inline-flex w-100 justify-content-center align-items-center">
                <p class="ur text-truncate overflow-hidden w-90 py-1 px-2">
                  {shareLink}
                </p>
                <button class="cpy mb-3 ps-1 py-1 px-2" onClick={handleCopy}>
                  <i class="fa fa-clone"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialShare;
